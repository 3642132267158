<template>
  <div>
    <header class="is-flex is-justify-content-space-between is-align-items-center">
      <div>
        <h3 class="has-text-weight-bold has-text-dark mb-2">Feed Index</h3>
        <p>Customize Feed Index Alert</p>
      </div>
      <b-button type="is-primary" @click="save">{{ $t('save') }}</b-button>
    </header>

    <div class="is-divider my-5"></div>

    <div class="card mb-4">
      <div class="card-content">
        <div class="columns is-align-items-center is-justify-content-space-between">
          <b-field label="Min. Range" class="column is-3-desktop">
            <b-input v-model.number="data.min_value"
                     step="any"
                     type="number"
                     @keyup.native="handleParameterInputValue(data)" :disabled="!canEditWqs"/>
          </b-field>

          <div class="px-4 is-flex-grow-1">
            <b-slider v-model="data.range_edit"
                      :min="data.min_limit"
                      :max="data.max_limit"
                      :step="data.step"
                      @input="handleUpdateSlider(data)" :disabled="!canEditWqs"/>
          </div>

          <b-field label="Max. Range" class="column is-3-desktop">
            <b-input v-model.number="data.max_value"
                     step="any"
                     type="number"
                     @keyup.native="handleParameterInputValue(data)" :disabled="!canEditWqs"/>
          </b-field>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "FeedIndexSetting",
  computed: {
    canAddWqs() {
      return !this.selectedFarm.farm_id || this.$store.getters["farm/canAddWqs"]
    },
    canEditWqs() {
      return this.$store.getters["farm/canEditWqs"]
    },
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    wqs: {
      get() {
        return this.$store.state.setting.wqs
      },
      set() {
        this.$store.dispatch('setting/toggleWqs')
      }
    }
  },
  data: () => {
    return {
      data: {
        min_value: 0,
        max_value: 0,
        range_edit: [0, 0],
        min_limit: 0,
        max_limit: 20,
        step: .01
      },
    }
  },
  created() {
    this.$loading(false)
    this.getData()
  },
  methods: {
    async getData() {
      this.$loading()

      let res = await this.$store.dispatch('farm/getFeedIndexSetting', this.farm_id)

      if (res && res.data) {
        this.data.min_value = res.data.min_value
        this.data.max_value = res.data.max_value
        this.data.range_edit = [this.data.min_value, this.data.max_value]
      }

      this.$loading(false)
    },
    handleUpdateSlider(parameter) {
      parameter.min_value = parameter.range_edit[0]
      parameter.max_value = parameter.range_edit[1]
    },
    handleParameterInputValue(parameter) {
      if (parameter.max_value > parameter.max_limit) parameter.max_value = parameter.max_limit
      if (parameter.min_value < parameter.min_limit) parameter.min_value = parameter.min_limit
      parameter.range_edit = [parameter.min_value, parameter.max_value]
    },
    async save() {
      this.$loading()
      await this.$store.dispatch('farm/updateFeedIndexSetting', {
        farm_id: parseInt(this.farm_id),
        min_value: this.data.min_value,
        max_value: this.data.max_value,
      })
      this.$loading(false)
    },
  }
}
</script>
